<template>
  <div class="pointer">
    <div class="header flex_al_js_center">
      <div class="logo">
        <img @click="goPage('/')" src="@/assets/logo.png" alt="">
      </div>
      <div class="tabs flex_al_js_center">
        <div class="tabs_item" @click="goPage('/')">首页</div>
        <div class="tabs_item" @click="jump(1,2)">中学</div>
        <div class="tabs_item" @click="jump(2,2)">高中</div>
        <div class="tabs_item" @click="jump(3,2)">大学</div>
        <div class="tabs_item" @click="jump1(8,2,false)">益思派</div>
        <div class="tabs_item" @click="goPage('/meiwen')">美文</div>
        <div class="tabs_item" v-if="isLogin" @click="goPage('/mine')">个人中心</div>
        <div class="tabs_item" v-else @click="goPage('/login')">登录/注册</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goPage(path) {
      if (this.$route.path == path) {
        return;
      }
      this.$routerGo(path);
    },
    jump(index, bannerType) {
      this.$router.push({
        path: "/list",
        query: { type: index, bannerType },
        force: true,
      });
      window.location.reload();
    },
    jump1(index, bannerType, isImage) {
      this.$router.push({
        path: "/list",
        query: { type: index, bannerType, isImage: isImage },
      });
    },
  },
  computed: {
    isLogin() {
      return localStorage.getItem("userInfo") ? true : false;
    },
  },
};
</script>

<style  scoped lang="scss">
.header {
  display: flex;
  width: 100%;
  height: 63px;
  .logo {
    text-align: center;
    flex: 1.5;
    width: 100px;
    height: 100px;
    img {
      width: 100px;
      height: 100px;
    }
  }

  .tabs {
    flex: 3;
    .tabs_item {
      display: flex;
      font-size: 22px;
      color: #333333;
      margin: 0 40px;
    }
  }
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import login from '@/views/login.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/reg',
    name: 'reg',
    component: ()=>import('@/views/reg.vue')
  },
  {
    path: '/forget',
    name: 'forget',
    component: ()=>import('@/views/forget.vue')
  },
  {
    path: '/list',
    name: 'list',
    component: ()=>import('@/views/list.vue')
  },
  {
    path: '/details',
    name: 'details',
    component: ()=>import('@/views/details.vue')
  },
  {
    path: '/mine',
    name: 'mine',
    component: ()=>import('@/views/mine.vue')
  },
  {
    path: '/bookdetails',
    name: 'bookdetails',
    component: ()=>import('@/views/bookdetails.vue')
  },
  
  {
    path: '/payzf',
    name: 'payzf',
    component: ()=>import('@/views/payzf.vue')
  },
  {
    path: '/meiwen',
    name: 'meiwen',
    component: ()=>import('@/views/meiwen.vue')
  },
  {
    path: '/mw_details',
    name: 'mw_details',
    component: ()=>import('@/views/mw_details.vue')
  },

  
]

const router = new VueRouter({
  history:'hash', // 使用 hash 模式
  routes
})

export default router

import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'


// create an axios instance
const service = axios.create({
  method:'post',
  // https://lessonapi.easeyisi.com
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL:'https://lessonapi.easeyisi.com',
  timeout: 5000 // request timeout
})

// 添加请求拦截器
service.interceptors.request.use(
  config => {
    // console.log(process.env.VUE_APP_BASE_API,'process.env.VUE_APP_BASE_API');
    if (store.getters.token) {
      // config.headers['Token'] = getToken()
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// 添加响应拦截器
service.interceptors.response.use(response => {
  // console.log("发送请求 ");
  // 二进制数据不拦截
  if (response.request.responseType === 'blob' || response.request.responseType === 'arraybuffer') {
    return response.data
  }

  const res = response.data
  // console.log(res,'拦截器');
  if (res.code !== 200) { // 异常状态码返回处理
    Message({
      message: res.msg || 'Error',
      type: 'error',
      duration: 5 * 1000
    })



    return res.msg
  } else {
    return res
  }

},
error => {

  Message({
    message: error.msg,
    type: 'error',
    duration: 5 * 1000
  })
  return  error
}
)

export default service

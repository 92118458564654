import HttpRequest from '@/utils/request'
// 获取手机号
export  function getPhoneSend(data){
	return HttpRequest({url:'/member/getphone',data})
}
// 用户登录
export  function  Userlogin(data){
	return HttpRequest({url:'/member/login',data})
}

// 忘记密码重置
export  function  forget_password(data){
	return HttpRequest({url:'/member/forget_password',data})
}



// 注册
export  function  userREG(data){
	return HttpRequest({url:'/member/reg',data})
}

// 发送短信
export  function  sendsms(data){
	return HttpRequest({url:'/member/sendsms',data})
}

// 登录获取用户信息
export  function  getUser(data){
	return HttpRequest({url:'/member/memberelse',data})
}

// 修改头像和昵称
export  function  update_info(data){
	return HttpRequest({url:'/member/update_info',data})
}
// 商品详情
export  function  goods_detail(data){
	return HttpRequest({url:'/member/goods_detail',data})
}
// 商品下单报名
export  function  buy_goods(data){
	return HttpRequest({url:'/member/buy_goods',data})
}

// 实名认证
export  function  authentication(data){
	return HttpRequest({url:'/Member/real',data})
}

// 实名认证
export  function  vip_lists(data){
	return HttpRequest({url:'/Member/vip_list',data})
}


// 购买会员套餐
export  function  buy_vip(data){
	return HttpRequest({url:'/Member/buy_vip',data})
}


// 用户预约住院
export  function  add_order(data){
	return HttpRequest({url:'/Member/add_order',data})
}

// 用户订单列表
export  function  getorder_list(data){
	return HttpRequest({url:'/member/order_list',data})
}

// 物流信息，暂时
export  function  safe_order(data){
	return HttpRequest({url:'/member/safe_order',data})
}

// 意见反馈
export  function  add_view(data){
	return HttpRequest({url:'/member/add_view',data})
}

// 我的课程
export  function  my_lesson(data){
	return HttpRequest({url:'/member/my_lesson',data})
}





// 好友和粉丝列表，仅业务经理和区域经理可见
export  function  bind_list(data){
	return HttpRequest({url:'/member/bind_list',data})
}

// 用户取消订单
export  function  cancel_order(data){
	return HttpRequest({url:'/member/cancel_order',data})
}

// 用户订单详情
export  function  order_detail(data){
	return HttpRequest({url:'/mendian/order_detail',data})
}

// 用户终止订单
export  function  stop_order(data){
	return HttpRequest({url:'/mendian/stop_order',data})
}

// 用户余额明细
export  function  balance_log(data){
	return HttpRequest({url:'/member/balance_log',data})
}
// 兑换商城列表
export  function  exchange_list(data){
	return HttpRequest({url:'/member/exchange_list',data})
}
// 兑换商城详情
export  function  exchange_detail(data){
	return HttpRequest({url:'/member/exchange_detail',data})
}

// 购买书籍
export  function  buy_exchange(data){
	return HttpRequest({url:'/member/buy_exchange_pc',data})
}
// 商品确认收货
export  function  receive_goods(data){
	return HttpRequest({url:'/member/receive_goods',data})
}

// 物流信息查询，嵌入商品订单详情页
export  function  wuliu_info(data){
	return HttpRequest({url:'/member/wuliu_info',data})
}







// 商家分享海报
export  function  getorder_lists(data){
	return HttpRequest({url:'/order/share',data})
}

// 商家支付佣金
export  function  mendian_pay(data){
	return HttpRequest({url:'/order/mendian_pay',data})
}

// 个人中心
export  function  gethomeinfo(data){
	return HttpRequest({url:'/member/memberelse',data})
}
// 提现列表
export  function  withdrawal_list(data){
	return HttpRequest({url:'/member/withdrawal_list',data})
}
// 用户发起提现
export  function  add_withdrawal(data){
	return HttpRequest({url:'/member/add_withdrawal',data})
}

// 佣金列表
export  function  commission_list(data){
	return HttpRequest({url:'/member/commission_list',data})
}

// 我的推广
export  function  agent_list(data){
	return HttpRequest({url:'/member/agent_list',data})
}

// 设置真实姓名和收款码
export  function  update_code(data){
	return HttpRequest({url:'/member/update_code',data})
}
// 邀请排行榜，前10名已自动排序
export  function  rank_list(data){
	return HttpRequest({url:'/member/rank_list',data})
}




// 用户上传外卖订单信息
export  function  update_order(data){
	return HttpRequest({url:'/order/update_order',data})
}


// 机构管理员展示自己的信息
export  function  show_mendian_user(data){
	return HttpRequest({url:'/mendian/show_mendian_user',data})
}
// 用户退款
export  function  refund(data){
	return HttpRequest({url:'/order/refund',data})
}
// 评价完整列表
export  function  ping_list(data){
	return HttpRequest({url:'/mendian/ping_list',data})
}
// 用户评价，只有已完成和已终止才能评价
export  function  pingjia(data){
	return HttpRequest({url:'/mendian/pingjia',data})
}
// 机构列表
export  function  mendian_list(data){
	return HttpRequest({url:'/mendian/mendian_list',data})
}
// 机构管理员新增/修改
export  function  usersave_mendian_user(data){
	return HttpRequest({url:'/user/save_mendian_user',data})
}
// 机构新增/修改
export  function  usersave_mendian(data){
	return HttpRequest({url:'/user/save_mendian',data})
}
// 机构删除
export  function  del_mendian(data){
	return HttpRequest({url:'/user/del_mendian',data})
}
// 机构管理员列表
export  function  mendian_user_list(data){
	return HttpRequest({url:'/user/mendian_user_list',data})
}
// 机构管理员删除
export  function  del_mendian_user(data){
	return HttpRequest({url:'/user/del_mendian_user',data})
}
// 机构管理员修改自己的资料
export  function  save_mendian_user_info(data){
	return HttpRequest({url:'/mendian/save_mendian_user_info',data})
}
// 留言板发送消息
export  function  send_message(data){
	return HttpRequest({url:'/member/send_message',data})
}
// 留言板记录，机构管理员和用户通用
export  function  message_list(data){
	return HttpRequest({url:'/member/message_list',data})
}
// 商务合作
export  function  join(data){
	return HttpRequest({url:'/member/join',data})
}
// 我的收藏
export  function  collection_list(data){
	return HttpRequest({url:'/mendian/collection_list',data})
}
// 收藏/取消收藏
export  function  set_collection(data){
	return HttpRequest({url:'/mendian/set_collection',data})
}
// 身份认证
export  function  add_info(data){
	return HttpRequest({url:'/member/add_info',data})
}
// 购物车内加减商品
export  function  update_cart(data){
	return HttpRequest({url:'/member/update_cart',data})
}
// 购物车下单
export  function  buy_specs(data){
	return HttpRequest({url:'/member/buy_specs',data})
}
// 拜访用户，仅status=1和4可传
export  function  visit_photo(data){
	return HttpRequest({url:'/member/visit_photo',data})
}


// 地址列表
export  function  getAddresslist(data){
	return HttpRequest({url:'/member/address_list',data})
}
// 新增地址列表
export  function  addAddress(data){
	return HttpRequest({url:'/member/add_address',data})
}
// 修改地址
export  function  updateAddress(data){
	return HttpRequest({url:'/member/update_address',data})
}
// 刪除地址
export  function  delAddress(data){
	return HttpRequest({url:'/member/del_address',data})
}
// 课程详情
export  function  specs_detail(data){
	return HttpRequest({url:'/member/specs_detail',data})
}


// 首页
export  function  homepage(data){
	return HttpRequest({url:'/member/homepage',data})
}
// 兑换商城加入购物车
export  function  add_exchange_cart(data){
	return HttpRequest({url:'/member/add_exchange_cart',data})
}
// 视频分类
export  function  type_list(data){
	return HttpRequest({url:'/member/type_list',data})
}
// banner
export  function  getbanner_list(data){
	return HttpRequest({url:'/member/banner_list',data})
}
// 好书分享列表
export  function  duihuanshop_list(data){
	return HttpRequest({url:'/member/shop_list',data})
}

// 兑换商品详情
export  function  dhshop_detail(data){
	return HttpRequest({url:'/member/shop_detail',data})
}

// 分享
export  function  share(data){
	return HttpRequest({url:'/member/share',data})
}
// 平台内容，只返回一条
export  function  notice(data){
	return HttpRequest({url:'/member/notice',data})
}
// 资讯列表
export  function  word_list(data){
	return HttpRequest({url:'/member/word_list',data})
}
// 用户积分明细
export  function  integral_log(data){
	return HttpRequest({url:'/member/integral_log',data})
}

// 商品加入购物车
export  function  add_cart(data){
	return HttpRequest({url:'/member/add_cart',data})
}
// 购物车，无分页
export  function  cart_list(data){
	return HttpRequest({url:'/member/cart_list',data})
}
// 书籍分类
export  function  book_type_list(data){
	return HttpRequest({url:'/member/book_type_list',data})
}

// 购买课程
export  function  buy_lesson(data){
	return HttpRequest({url:'/member/buy_lesson_pc',data})
}


// 商品列表
export  function  getgoodslist(data){
	return HttpRequest({url:'/member/specs_list',data})
}
// 下单
export  function  Payputorder(data){
	return HttpRequest({url:'/Order/putorder',data})
}
// 订单列表
export  function  getOrder(data){
	return HttpRequest({url:'/Order/index',data})
}

// 订单详情
export  function  getOrderDetails(data){
	return HttpRequest({url:'/Order/detail',data})
}

// 订单取消
export  function  orderCancel(data){
	return HttpRequest({url:'/Order/cancel',data})
}

// 平台配置
export  function  version_info(data){
	return HttpRequest({url:'/member/version_info',data})
}

// 酒店详情页
export  function  mendian_detail(data){
	return HttpRequest({url:'/mendian/mendian_detail',data})
}

// 银行卡列表
export  function  bank_list(data){
	return HttpRequest({url:'/member/bank_list',data})
}

// 银行卡新增
export  function  add_bankcard(data){
	return HttpRequest({url:'/member/add_bankcard',data})
}
// 银行卡新增
export  function  update_bankcard(data){
	return HttpRequest({url:'/member/update_bankcard',data})
}

// 银行卡删除
export  function  del_bankcard(data){
	return HttpRequest({url:'/member/del_bankcard',data})
}

// 用户下单-支付余额
export  function  pay_order(data){
	return HttpRequest({url:'/mendian/pay_order',data})
}

// 邀请
export  function  workerInvite(data){
	return HttpRequest({url:'/member/invite',data})
}

// 上传接口
export  function  uploadIndex(data){
	return HttpRequest({url:'/index/upload',data})
}

// 上传接口
export  function  isdeposit(data){
	return HttpRequest({url:'/order/is_deposit',data})
}


// 上传接口
export  function  getproperty(data){
	return HttpRequest({url:'/member/property',data})
}


// 上传接口
export  function  setrecover(data){
	return HttpRequest({url:'/member/recover',data})
}
// 商品订单列表
export  function  exchange_mylist(data){
	return HttpRequest({url:'/member/exchange_mylist',data})
}



// 商品兑换酒店订单
export  function  use_goods(data){
	return HttpRequest({url:'/member/use_goods',data})
}



// 回收列表
export  function  getrecover_list(data){
	return HttpRequest({url:'/worker/recover_list',data})
}


// 提交回收
export  function  finish_recover(data){
	return HttpRequest({url:'/worker/finish_recover',data})
}


// 提交回收
export  function  order_finish(data){
	return HttpRequest({url:'/worker/order_finish',data})
}



// 实名认证
export  function  writeSing(data){
	return HttpRequest({url:'/Member/write',data})
}

// 配送账本
export  function  send_book(data){
	return HttpRequest({url:'/worker/send_book',data})
}

// 充值套餐列表
export  function  recharge_list(data){
	return HttpRequest({url:'/mendian/recharge_list',data})
}
// 用户充值
export  function  recharge(data){
	return HttpRequest({url:'/fuyou/recharge',data})
}





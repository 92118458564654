<template>
  <div class="home">
    <headerTab></headerTab>
    <div class="banner">
      <el-carousel indicator-position="outside">
        <el-carousel-item v-for="(item,i) in banner_list" :key="i">
          <img class="bannerIMG" :src="item.photo" alt="">
        </el-carousel-item>
      </el-carousel>
      <!-- <img class="bannerIMG" src="@/assets/banner.png" alt=""> -->
    </div>
    <div class="pageView">
      <!-- 热门课程    视频-->
      <div class="kecheng">
        <div class="title">热门课程</div>
        <!-- 课程列表 -->
        <div class="kecheng_c" style="overflow:auto">
          <template v-if="goods_list.length>0">
            <div class="kecheng_item pointer" @click="pay(item)" v-if="index<6" v-for="(item, index) in goods_list">
              <img :src="item.photo" alt="">
              <div class="name  ellipsis">{{item.specs_name}}</div>
              <div class="fneei">
                <span class="  sell_num  ellipsis">{{item.type_name}}</span>
                <span>{{item.sell_num}}报名</span>
              </div>
              <div class="price">￥{{item.price}}</div>
              <div class="pay flex_al_js_center ">购买课程</div>
            </div>
          </template>
          <template v-else>
            <div class="empyt">
              <el-empty :image-size="300" description="暂无课程"></el-empty>
            </div>
          </template>

        </div>
        <div class="gengduo pointer" @click="jump(0,2)"> 查看更多>></div>
      </div>
      <!-- 益思派乐园 -->
      <div class="kecheng">
        <div class="titlebox pointer">
          <div class="title">益思派乐园</div>
          <div class="selece">
            <div @click="toggle(4,false)" :class="{'select_active':currentIndex==4}" class="selece_item ">词汇</div>
            <div @click="toggle(5,false)" :class="{'select_active':currentIndex==5}" class="selece_item">语法</div>
            <div @click="toggle(6,false)" :class="{'select_active':currentIndex==6}" class="selece_item">电影</div>
            <div @click="toggle(7,true)" :class="{'select_active':currentIndex==7}" class="selece_item">好书分享</div>
          </div>
        </div>

        <!-- 课程列表   包含视频和书  只有好书分享展示 书籍 -->
        <div class="kecheng_c " style="overflow:auto">

          <template v-if="list1.length>0">
            <template v-if="currentIndex!=7">
              <div class="kecheng_item pointer " @click="pay(item)" v-if="index<6" v-for="(item, index) in list1">
                <img :src="item.photo" alt="">
                <div class="name  ellipsis">{{item.specs_name}}</div>
                <div class="fneei">
                  <span class="  sell_num  ellipsis">{{item.type_name}}</span>
                  <span>{{item.sell_num}}报名</span>
                </div>
                <div class="price">￥{{item.price}}</div>
                <div class="pay flex_al_js_center ">购买课程</div>
              </div>
            </template>
            <template v-else>
              <div class="kecheng_item pointer " @click="paybook(item)" v-if="index<6" v-for="(item, index) in list1">
                <img :src="item.photo" alt="">
                <div class="name  ellipsis">{{item.specs_name}}</div>
                <div class="fneei">
                  <span>{{item.name}}</span>
                  <span>{{item.describe}}</span>
                </div>
                <div class="price">
                  <span>￥{{item.price}}</span>
                  <span class="zeprice">￥{{item.old_price}}</span>
                </div>

                <div class="pay flex_al_js_center ">购买书籍</div>
              </div>
            </template>
          </template>

          <template v-else>
            <div class="empyt">
              <el-empty :image-size="300" description="暂无课程"></el-empty>
            </div>
          </template>

        </div>
        <div class="gengduo pointer" @click="jump1(currentIndex,2)"> 查看更多>></div>
      </div>

    </div>
    <div class="fankui flex_al_js_center">
      <div class="fankui_box ">
        <div class="subtitle">反馈问题</div>
        <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="textarea">
        </el-input>
        <div class="btn  flex_al_js_center" @click="editInfo">提交</div>
      </div>
    </div>

  </div>
</template>

<script>
import {
  getgoodslist,
  getbanner_list,
  duihuanshop_list,
  type_list,
  getUser,
  add_view,
  add_cart,
} from "@/utils/network.js";
export default {
  data() {
    return {
      textarea: "",
      goods_list: [],
      list1: [],
      heightScroll: [],
      banner_list: [],
      currentIndex: 4,
      queryParams: {
        page: 1,
        limit: 10,
      },

      isImage: false,
    };
  },
  created() {
    // banner
    this.getbanner_lists();
    // 首頁課程列表
    this.int();
    // 乐园
    this.getlist2();
  },
  methods: {
    async editInfo() {
      if (!this.textarea) {
        this.$message({
          message: "请填写",
          type: "error",
        });
        return;
      }
      let data = {
        member_id: JSON.parse(localStorage.getItem("member_id")),
        content: this.textarea,
      };
      const res = await add_view(data);

      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: "success",
        });
      }
    },
    pay(item) {
      let member_id = localStorage.getItem("member_id") || null;
      if (!member_id) {
        this.$message({
          message: "请先登录",
          type: "error",
        });
        return;
      }
      this.$router.push({ path: "/details", query: { id: item.id } });
    },
    paybook(item) {
      let member_id = localStorage.getItem("member_id") || null;
      if (!member_id) {
        this.$message({
          message: "请先登录",
          type: "error",
        });
        return;
      }
      this.$router.push({
        path: "/bookdetails",
        query: { item: JSON.stringify(item) },
      });
    },
    jump(index, bannerType) {
      this.$router.push({ path: "/list", query: { type: index, bannerType } });
    },
    jump1(index, bannerType) {
      this.$router.push({
        path: "/list",
        query: { type: index, bannerType, isImage: this.isImage },
      });
    },
    toggle(index, isImage) {
      this.currentIndex = index;
      if (index == 7) {
        this.gduihuanshop_list();
      } else {
        this.getlist2();
      }

      this.isImage = isImage; // 判断是图片还是视频
    },
    async getbanner_lists() {
      const res = await getbanner_list({
        type: 1,
      });
      this.banner_list = res.data;
    },

    int() {
      this.goods_list = [];
      this.queryParams = {
        page: 1,
        limit: 10,
      };
      this.getgoodslists();
    },
    // 乐园
    async getgoodslists() {
      const res = await getgoodslist({
        is_top: 2,
        ...this.queryParams,
        // text: this.keyword,
      });
      this.goods_list = res.data.res;
    },
    async getlist2() {
      let data = {
        level_id: this.currentIndex,
      };
      const res = await getgoodslist(data);
      this.list1 = res.data.res;
    },
    // 获取书籍
    async gduihuanshop_list() {
      const res = await duihuanshop_list({
        ...this.queryParams,
      });
      this.list1 = res.data.res;
    },
  },
};
</script>


<style   lang="scss" scoped>
.banner {
  width: 100%;
  max-width: 100vw;
  height: 781px;
  // background-color: red;
  .bannerIMG {
    width: 100vw;
    max-width: 100vw;
    min-width: 100vh;
    height: 781px;
    object-fit: fill !important;
  }
}
::v-deep .el-carousel__container {
  height: 781px !important;
}
.pageView {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .kecheng {
    width: 1000px;
    .title {
      width: 186px;
      height: 46px;
      font-size: 25px;
      color: #182837;

      border-bottom: 10px solid #e61d52;
    }
    .titlebox {
      display: flex;
      justify-content: space-between;
      .selece {
        display: flex;
        .selece_item {
          font-size: 24px;
          color: #182837;

          margin: 0 20px;
        }
        .select_active {
          color: #e61d52;
        }
      }
    }
    .kecheng_c {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      .kecheng_item {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 30%;
        margin: 0 10px;

        img {
          width: 100%;
          height: 256px;
        }
        .name {
          text-align: center;
          width: 100%;
          font-size: 30px;
          color: #182837;
        }
        .fneei {
          width: 100%;
          display: flex;
          // text-align: center;
          justify-content: center;
          // justify-content: space-between;
          margin: 10px 0;
          .sell_num {
            width: 100px;
          }
        }
        .price {
          height: 29px;
          font-size: 30px;
          color: #e61d52;
        }
        .zeprice {
          height: 29px;
          font-size: 20px;
          color: #333;
          text-decoration-line: line-through;
        }
        .pay {
          width: 179px;
          height: 49px;
          background: #e61d52;
          border-radius: 20px;
          margin: 20px 0;
          font-size: 24px;
          color: #ffffff;
        }
      }
    }
  }
  .gengduo {
    font-size: 30px;
    color: #e61d52;
    text-align: center;
    margin: 20px 0;
    margin-bottom: 40px;
  }
}
.fankui {
  margin-top: 40px;
  background: #182837;
  .fankui_box {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-content: center;
    width: 1160px;
  }
  .subtitle {
    font-size: 36px;
    color: #fefeff;
    text-align: center;
    margin: 20px 0;
  }
  .btn {
    margin: 15px auto;
    width: 191px;
    height: 46px;
    background: #ff1948;

    font-size: 30px;
    color: #fefeff;
  }
}
</style>
